<template>
<div>
  <page-banner>
    <template #banner-txt>
      表格下載
    </template>
    <template #banner-img>
      <img v-lazy="banner" class="img-center">
    </template>
  </page-banner>

  <div class="common-bg padding-50">
     <div class="container">
       <div class="table-download">
          <ul>
            <li v-for="item in list" :key="item">
              <div class="title">
                {{ item.name }}
              </div>
              <div class="files">
                 <div v-if="item.pdf_file!= null">
                   <a v-bind:href="item.pdf_file" download>
                    <span>
                     <img src="@/assets/img/icon_pdf.png">
                    </span>
                     <span>下載</span>
                   </a>
                 </div>
                 <div v-if="item.word_file!= null">
                   <a v-bind:href="item.word_file">
                    <span>
                     <img src="@/assets/img/icon_word.png">
                    </span>
                    <span>下載</span>
                  </a>
                 </div>

               </div>
            </li>
          </ul>
       </div>
      <!--ref 可让父组件访问子组件的数据-->
      <loading-list ref="changeView"></loading-list>

     </div>
  </div>




</div>
</template>

<script>
import pageBanner from "@/components/PageBanner";
import loadingList from "@/components/loadingList";

import {getDownloadList} from "@/network/home";


export default {
  name: "tableDownload",
  components:{
    pageBanner,
    loadingList,
  },
  data() {
    return {
      banner:{},
      list:[],
      page:0,
    }
  },
  created() {
    this.getDownloadList()
  },
  mounted() {
    //监视scroll滚动条
    window.addEventListener("scroll", this.isRefresh, true);
  },

  methods:{

    //判断滚动条是否到底部，刷新新的数据
    isRefresh() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
     //console.log(scrollTop + windowHeight + "--" + scrollHeight);
      if (
          scrollTop + windowHeight >= scrollHeight - 600 &&
          this.isRefreshBool
      ) {
        // false防止refresh()加载数据函数多次触发
        this.isRefreshBool = false;
        this.getDownloadList();
      }
    },

    getDownloadList() {
      const page = this.page +1
      getDownloadList(page) .then(res =>{
       //banner
        this.banner = res.data.data.banner;

        if (page > 1){
          this.$refs.changeView.loading = true;
        }

        //list
       const length = res.data.data.data.length;
       // const per_page = res.data.data.per_page;
        if (length > 0 ) {
          this.list.push(...res.data.data.data);
          this.page += 1;
          //refresh()加载数据函数可以触发
          this.isRefreshBool = true;
          //this.$refs.changeView.loading = true;
         }else {
          console.log("没有数据了")
          this.$refs.changeView.loading = false;
          this.$refs.changeView.noMore = true;
        }



      })
    }




  }
}
</script>

<style scoped>
.table-download ul{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.table-download ul li{
  flex: 0 0 48%;
  max-width: 100%;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #332D30;
  padding: 20px;
  border-radius: 4px;
}

.table-download ul li:nth-child(odd) {
 margin-right: 2%;
}

.table-download ul li:nth-child(even) {
  margin-left: 2%;
}

.title{
  font-size: 20px;
  font-weight: bold;
  width: calc(100% - 220px);
}

.files{
  width: 240px;
  display: flex;
  justify-content: flex-end;
}

.files a{
 padding-left: 20px;
}

.files img{
  margin-right: 5px;
}



@media only screen and (min-width:0px) and (max-width:991px) {
  .table-download ul li{
    flex: 0 0 100%;
    margin-bottom: 20px;
    flex-wrap: wrap;
   }
  .table-download ul li:nth-child(odd) {
    margin-right: 0;
  }

  .table-download ul li:nth-child(even) {
    margin-left: 0;
  }
  .title{
    font-size: 16px;
    /*width: 100%;*/
  }

}

@media only screen and (min-width:0px) and (max-width:991px) {
  .title{
    width: 100%;
  }
  .files{
    width: 100%;
    justify-content: flex-start;
    margin-top: 15px;
  }

  .files a {
    padding-left: 0;
    padding-right: 20px;
  }

  .files img{
    width: 28px;
   }


}



</style>